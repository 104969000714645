.searchstatus {
  position: absolute;
  left: 35%;
  top: 45%;
  opacity: 0.3;
  font-weight: 500;
  font-size: 17px;
}
.loaderposition {
  position: absolute;
  top: 45%;
  left: 45%;
}
.empsignup {
  background-color: #6b79ff;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.header {
  font-size: 24px;
  font-weight: 500;
  color: white;
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.cardshadow {
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
}
.loadingstyle {
  font-size: 26px;
  font-weight: 500;
  color: white;
  font-family: "Comic Sans MS", cursive, sans-serif;
  text-transform: lowercase;
  position: absolute;
  top: 45%;
  left: 38%;
}
.domainstyle {
  text-transform: capitalize;
  font-size: 20px;
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.versionstyle {
  color: white;
  position: absolute;
  bottom: 1%;
  left: 30%;
  font-size: 13px;
  opacity: 0.8;
}
